import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPathListener } from "./components/redux/appSlice";
import "./App.css";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import Start from "./views/start/Start";
import Info from "./views/info/Info";
import Teams from "./views/teams/Teams";
import Scoring from "./views/scoring/Scoring";
import Contact from "./views/contact/Contact"

const routesArray = ["info", "lag", "scoring", "kontakt"];

export default function AppStart() {
  const dispatch = useDispatch();
  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {
      dispatch(setPathListener(false));
      console.log("begin", arguments);
    });
    Events.scrollEvent.register("end", function (to, element) {
      dispatch(setPathListener(true));
      console.log("end", arguments);
    });

    scrollSpy.update();
    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  });

  

  useEffect(() => {
    let pathFromUrl = window.location.pathname.substring(1);
    if (routesArray.includes(pathFromUrl)) {
      scroller.scrollTo(pathFromUrl, {
        duration: 1000,
        delay: 50,
        smooth: true,
      });
    } else {
      scroll.scrollToTop();
    }
  }, []);

  return (
    <div className="App">
      <Start />
      <Info/>
      <Teams/>
      <Scoring/>
      <Contact/>
    </div>
  );
}
