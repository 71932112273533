import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    pathListener: true,
  },
  reducers: {
    setPathListener: (state, action) => {
      state.pathListener = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setPathListener } = appSlice.actions

export default appSlice.reducer