import React from "react"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'

export const useOnScreen =(ref) => {

  const [isIntersecting, setIntersecting] = useState(false)
  const checkIfPath = useSelector((state) => state.app.pathListener)

  useEffect(() => {
      if (checkIfPath === true) {
          const observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting),
            {threshold: 0.6}
        )
            observer.observe(ref.current)
            // Remove the observer as soon as the component is unmounted
            return () => { observer.disconnect() }
      }
  }, [ref, checkIfPath])

  return isIntersecting
}