import React, { useState, useRef } from "react";
import { Waypoint } from "react-waypoint";
import { useSpring, animated as a } from "react-spring";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import "./start.css";

import innerHeight from "ios-inner-height";

import { useOnScreen } from "../../components/utils/utils";
import LjBowlingText from "./LjBowlingText";
import LjBowlingLogo from "./LjBowlingLogo";
import SocialIcons from "../../components/misc/SocialIcons";

export default function Start() {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [isInView, setInView] = useState(false);

  if (isVisible === true && window.location.pathname !== "/") {
    window.history.replaceState(null, "Start", "/");
  }

  const transition = useSpring({
    delay: 1600,
    to: {
      opacity: !isInView ? 0 : 1,
    },
  });

  return (
    <Element name="start" style={{ height: innerHeight() }}>
      <Waypoint
        bottomOffset={window.innerHeight * 0.3}
        scrollableAncestor={window}
        onEnter={() => setInView(true)}
      >
        <div ref={ref} className="Start">
          
          <div className="StartInner">
            <a.div className="StartLogo" style={transition}>
              <LjBowlingLogo />
            </a.div>
            <div className="StartText">
              <LjBowlingText isInView={isInView} />
            </div>
          </div>
          <a.div className="StartSocial" style={transition}>
            <SocialIcons />
          </a.div>
        </div>
      </Waypoint>
    </Element>
  );
}
