import React from "react"
import './misc.css';

class GoogleMaps extends React.PureComponent {

  render() {
    return (
      <iframe
        className={'MapsElement'}
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCVCOdSlc7E6mjBRxzZACKF4UxGZz3Z8Ws&q=klörupsvägen+48,trelleborg">
      </iframe>
    )
  }
}

export default GoogleMaps;