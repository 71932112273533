import React, { useState, useRef } from "react";
import { Waypoint } from "react-waypoint";
import { useSpring, animated as a } from "react-spring";
import { useSelector, useDispatch } from "react-redux";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import { useOnScreen } from "../../components/utils/utils";
import SocialIcons from "../../components/misc/SocialIcons";
import GoogleMaps from "../../components/misc/GoogleMaps";

import "./contact.css";

export default function Info() {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const pathListener = useSelector((state) => state.app.pathListener);
  const [isInView, setInView] = useState(false);

  if (isVisible === true && window.location.pathname !== "/kontakt") {
    window.history.replaceState(null, "Kontakt", "/kontakt");
  }

  const transition = useSpring({
    delay: 100,
    to: {
      opacity: !isInView ? 0 : 1,
    },
  });

  return (
    <Element name="contact" className="Contact">
      <Waypoint
        bottomOffset={window.innerHeight * 0.3}
        scrollableAncestor={window}
        onEnter={() => setInView(true)}
      >
        <a.div style={transition} ref={ref} className="Contact">
          <div className="ContactSocial">
            <SocialIcons />
          </div>
          <div className="ContactInner">
            <div className="ContactOpening">
              <p className="InfoTitle">ÖPPETTIDER</p>
              <p>Måndag - Lördag...............13.00-20.00</p>
              <p>Söndag...............................13.00-17.00</p>
            </div>
            <p className="InfoText">
              Vi finns i Söderslättshallen och tar emot banbokningar på telefon
              och epost.
            </p>
          </div>
          <div className='ContactInner'>
                <GoogleMaps/>
                <div className="ContactInner">
                    <div className="ContactAddressInner">
                        <p >LJ Bowling AB</p>
                        <p>Klörupsvägen 48</p>
                        <p>231 56 Trelleborg</p>
                    </div>
                    <div className="ContactAddressInner">
                        <p>1234567890</p>
                        <p>&nbsp;</p>
                        <p>info@lj-bowling.se</p>
                    </div>
                </div>      
                </div>
                <div className="WebmasterDiv">
        <Link 
            href="mailto:jimmy@vert.farm"
            sx={{color: '#fff', fontSize: '75%'}}
            >Webmaster</Link>
        </div>
        </a.div>
      </Waypoint>
    </Element>
  );
}
