import React from "react";
import './misc.css';

function SocialIcons() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="977.12"
      height="220"
      data-name="Layer 4"
      viewBox="0 0 977.12 220"
      className="SocialIcons"
    >
      <path
        d="M974.77,543c-1.32-9.17-8.4-16.32-17.52-18.45-35.43-8.31-44.31-28.71-45.81-51.35C905.15,472,894,470.75,875,470.75s-30.15,1.27-36.44,2.43c-1.5,22.64-10.38,43-45.81,51.35-9.12,2.14-16.2,9.28-17.52,18.45l-6.83,47.33C766,607,778.23,622,795.25,622h159.5c17,0,29.26-15,26.85-31.69ZM875,587.51a34.38,34.38,0,1,1,34.73-34.37A34.56,34.56,0,0,1,875,587.51ZM1011.81,457c-.33-20.62-52.82-55-136.81-55s-136.5,34.38-136.81,55,.29,47.46,34.85,43c40.44-5.24,38-19.36,38-39.55,0-14.08,32.89-17.47,64-17.47s64,3.39,64,17.47c0,20.19-2.49,34.31,38,39.55C1011.51,504.46,1012.13,477.62,1011.81,457Z"
        transform="translate(-34.73 -402)"
        fill="#357180"
      />
      <path
        d="M665.09,417.62A15.61,15.61,0,0,0,649.48,402h-275a15.61,15.61,0,0,0-15.61,15.62V606.38A15.61,15.61,0,0,0,374.52,622h275a15.61,15.61,0,0,0,15.61-15.62Zm-31.23,31.81a15.68,15.68,0,0,1-5.52,11.94l-59.9,51,60,51a15.52,15.52,0,0,1-10.07,27.34h-.13A16,16,0,0,1,607.85,587l-63.5-54.06-22.22,18.92a15.62,15.62,0,0,1-20.26,0l-22.22-18.92-63.4,54a16.28,16.28,0,0,1-10.58,3.9,15.52,15.52,0,0,1-10.07-27.34l60-51-59.07-50.29a18,18,0,0,1-6.35-13.74,15.11,15.11,0,0,1,24.9-11.5l92,78.36a7.63,7.63,0,0,0,9.88,0l92-78.36a15.11,15.11,0,0,1,24.9,11.5v1.09Z"
        transform="translate(-34.73 -402)"
        fill="#357180"
      />
      <path
        d="M246.94,402H53.08a18.32,18.32,0,0,0-18.35,18.33V603.68A18.34,18.34,0,0,0,53.08,622H246.94a18.35,18.35,0,0,0,18.33-18.32V420.33A18.34,18.34,0,0,0,246.94,402Zm-57.25,65.32c-4.76-2.79-8.54-5-13.29-5s-8.63,1.16-10.66,3.12-3,5.69-3,11.25v8.21h24.86l-5.38,22.86H162.7V583.7H131.47V507.77H116.89V484.91h14.59v-8.72c0-9.19,1.1-14.69,3.27-19.22A27.27,27.27,0,0,1,147,444.56c5.83-2.84,14.45-4.27,23.38-4.27,9.15,0,16.29,3,25,5.51Z"
        transform="translate(-34.73 -402)"
        fill="#357180"
      />
    </svg>
  );
}

export default SocialIcons;
