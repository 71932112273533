import React, { useState, useRef } from "react";
import { Waypoint } from "react-waypoint";
import { useSpring, animated as a } from "react-spring";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  WithStore,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import { useOnScreen } from "../../components/utils/utils";
import leftArrow from "../../static/leftArrow.svg";
import rightArrow from "../../static/rightArrow.svg";
import calendarImg from "../../static/calendar.jpg";

import "./teams.css";

const clubInfoArr = [
  {
    name: 'BK Borgen',
    website: 'http://www.bkborgen.se',
    info: 'Klicka på bilden för att besöka deras webbsida!',
    logoUrl: 'https://cdn.svenskalag.se/img/clubmarks/7374.png?2'
  },
  {
    name: 'T-Nabben BK',
    website: 'https://www.svenskalag.se/t-nabbenbowling',
    info: 'Klicka på bilden för att besöka deras webbsida!',
    logoUrl: 'https://cdn.svenskalag.se/img/clubmarks/7504.png?2'
  },
  {
    name: 'BK Peter Pan',
    website: 'http://bkpeterpan.dinstudio.se',
    info: 'Klicka på bilden för att besöka deras webbsida!',
    logoUrl: 'http://bkpeterpan.dinstudio.se/files/800x200/img002.JPG'
  },
  {
    name: 'Slättens PBK',
    website: 'http://slatten.dinstudio.se',
    info: 'Klicka på bilden för att besöka deras webbsida!',
    logoUrl: 'http://slatten.dinstudio.se/files/250x600/PBK_Sltten.jpg'
  },
  {
    name: 'Palmstadens HBK',
    website: 'https://www.svenskalag.se/PalmstadensHBK',
    info: 'Klicka på bilden för att besöka deras webbsida!',
    logoUrl: 'https://cdn03.svenskalag.se/images/10677/112100/bild.jpg?0'
  },
]

export default function Teams() {

    let navigate = useNavigate()
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [isInView, setInView] = useState(false);
  const [sliderIndex, setSliderIndex] = useState(0);

  if (isVisible === true && window.location.pathname !== "/lag") {
    window.history.replaceState(null, "Lag", "/lag");
  }

  const transition = useSpring({
    delay: 100,
    to: {
      opacity: !isInView ? 0 : 1,
    },
  });

  const handleButtonBack = () => {
    if (sliderIndex === 0) {
      setSliderIndex(clubInfoArr.length -1);
    } else {
      setSliderIndex(sliderIndex - 1);
    }
  };
  const handleButtonNext = () => {
    if (sliderIndex === (clubInfoArr.length -1)) {
      setSliderIndex(0);
    } else {
      setSliderIndex(sliderIndex + 1);
    }
  };

  return (
    <Element name="lag" className="Teams">
      <Waypoint
        bottomOffset={window.innerHeight * 0.3}
        scrollableAncestor={window}
        onEnter={() => setInView(true)}
      >
        <a.div style={transition} ref={ref} className="Teams">
              <div className="TeamsInnerText">
                  <p className="TeamsTitle">FÖRENINGAR</p>
                  <p className="TeamsText">
                   Bowlingföreningar som spelar och tränar hos oss i hallen.
                  </p>
                </div>
                <div className="TeamsImage">
                <CarouselProvider
                  naturalSlideWidth={200}
                  naturalSlideHeight={300}
                  totalSlides={clubInfoArr.length}
                  infinite={true}
                  visibleSlides={1}
                  className="TeamsImageCarousel"
                  touchEnabled={false}
                  dragEnabled={false}
                >
                  <Slider className="TeamsImageSlider">
                    {clubInfoArr.map((project, index) => {
                      return (
                        <Slide
                          className="TeamsImageSlide"
                          index={index}
                          key={index}
                        >
                          <a href={project.website} rel="noreferrer" target="_blank">
                            <img
                            className="TeamsImageInner"
                            src={project.logoUrl}
                            alt={project.name}
                          />
                          </a>
                        </Slide>
                      );
                    })}
                  </Slider>
                  <div className="TeamsButtons">
                    <ButtonBack
                      className="TeamsButton"
                      onClick={handleButtonBack}
                    >
                      <img
                        className="ButtonArrow"
                        src={leftArrow}
                        alt={"Left arrow"}
                      />
                    </ButtonBack>
                    <p className="TeamsButtonsText">{clubInfoArr[sliderIndex].name}</p>
                    <ButtonNext
                      type={""}
                      className="TeamsButton"
                      onClick={handleButtonNext}
                    >
                      <img
                        className="ButtonArrow"
                        src={rightArrow}
                        alt={"Right arrow"}
                      />
                    </ButtonNext>
                  </div>
                </CarouselProvider>
              </div>
              <div className="TeamsLowerText">
                <p className="TeamsLowerInnerText">
                  {clubInfoArr[sliderIndex].info}
                </p>
              </div>
              <div className="TeamsBottom" onClick={() => navigate('/kalender')}>
                <img
                            className="TeamsCalendarImg"
                            src={calendarImg}
                            alt={'Calendar Link'}
                          />
                <p className="TeamsBottomText">KALENDER</p>
              </div>
            </a.div>
      </Waypoint>
    </Element>
  );
}
