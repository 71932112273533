import React, { useState, useRef } from "react";
import { Waypoint } from "react-waypoint";
import { useSpring, animated as a } from "react-spring";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  WithStore,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import { useOnScreen } from "../../components/utils/utils";
import leftArrow from "../../static/leftArrow.svg";
import rightArrow from "../../static/rightArrow.svg";
import calendarImg from "../../static/calendar.jpg";

import "./scoring.css";

const scoreArray = [
  "https://scoring.se/661/547906/1_small.jpg?nocache=745113",
  "https://scoring.se/661/547906/2_small.jpg?nocache=672935",
  "https://scoring.se/661/547906/3_small.jpg?nocache=873557",
  "https://scoring.se/661/547906/4_small.jpg?nocache=48504",
  "https://scoring.se/661/547906/5_small.jpg?nocache=857978",
  "https://scoring.se/661/547906/6_small.jpg?nocache=32924",
  "https://scoring.se/661/547906/7_small.jpg?nocache=154716",
  "https://scoring.se/661/547906/8_small.jpg?nocache=329662",
];

export default function Scoring() {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [isInView, setInView] = useState(false);
  const [sliderIndex, setSliderIndex] = useState(0);

  if (isVisible === true && window.location.pathname !== "/scoring") {
    window.history.replaceState(null, "Scoring", "/scoring");
  }

  const transition = useSpring({
    delay: 100,
    to: {
      opacity: !isInView ? 0 : 1,
    },
  });

  const handleButtonBack = () => {
    if (sliderIndex === 0) {
      setSliderIndex(scoreArray.length - 1);
    } else {
      setSliderIndex(sliderIndex - 1);
    }
  };
  const handleButtonNext = () => {
    if (sliderIndex === scoreArray.length - 1) {
      setSliderIndex(0);
    } else {
      setSliderIndex(sliderIndex + 1);
    }
  };

  return (
    <Element name="scoring" className="Scoring">
      <Waypoint
        bottomOffset={window.innerHeight * 0.3}
        scrollableAncestor={window}
        onEnter={() => setInView(true)}
      >
        <a.div style={transition} ref={ref} className="Scoring">
          <div className="ScoringInnerText">
            <p className="ScoringTitle">SCORING</p>
            <p className="ScoringText">
              Se live scoring här på sidan eller besök{" "}
              <a
                href="https://scoring.se/choose.asp?alley=661"
                target="_blank"
                rel="noreferrer"
              >
                scoring.se
              </a>{" "}
              för att se direkt där.
            </p>
          </div>
          <div className="ScoringImage">
            <CarouselProvider
              naturalSlideWidth={200}
              naturalSlideHeight={300}
              totalSlides={scoreArray.length}
              infinite={true}
              visibleSlides={1}
              className="ScoringImageCarousel"
              touchEnabled={false}
              dragEnabled={false}
            >
              <Slider className="ScoringImageSlider">
                {scoreArray.map((project, index) => {
                  return (
                    <Slide
                      className="ScoringImageSlide"
                      index={index}
                      key={index}
                    >
                      <a
                        href={project.website}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          className="ScoringImageInner"
                          src={project}
                          alt={'Live Scoring'}
                        />
                      </a>
                    </Slide>
                  );
                })}
              </Slider>
              <div className="ScoringButtons">
                <ButtonBack className="ScoringButton" onClick={handleButtonBack}>
                  <img
                    className="ButtonArrow"
                    src={leftArrow}
                    alt={"Left arrow"}
                  />
                </ButtonBack>
                <p className="ScoringButtonsText">
                  {'Bana ' + (sliderIndex + 1)}
                </p>
                <ButtonNext
                  type={""}
                  className="ScoringButton"
                  onClick={handleButtonNext}
                >
                  <img
                    className="ButtonArrow"
                    src={rightArrow}
                    alt={"Right arrow"}
                  />
                </ButtonNext>
              </div>
            </CarouselProvider>
          </div>
        </a.div>
      </Waypoint>
    </Element>
  );
}
