import React from "react";
import './start.css';

function LjBowlingLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="874.58"
      height="1000"
      data-name="Layer 3"
      viewBox="0 0 874.58 1000"
      className="StartInnerLogo"
    >
      <path
        fill="#f2f2f2"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M795.43 74.65c-22.64 1.08-47.75 12-62.25 43.47-23.19 50.36-30.37 121.74-63.33 193.3S510.21 427.6 449.17 560.13 395.41 854.9 395.41 854.9l44.73 20.51h129.64c42.91-41 106.9-110.9 144.9-193.41 61-132.53 12.47-257.6 45.44-329.16s82.59-123.4 105.79-173.76S854.54 92 830.64 81a78.06 78.06 0 00-35.21-6.35z"
      ></path>
      <path
        fill="#e6e6e6"
        fillRule="evenodd"
        d="M907.11 94.74c-7.65-3.51-29.18 7.46-52.38 57.84S810 267.68 777 339.25s-100.07 143.52-161.1 276S516.1 889 516.1 889l-44.25-20.31s-7.27-162.28 53.77-294.81S713.34 396.72 746.3 325.15s40.11-142.93 63.31-193.29 73.6-48.09 97.5-37.12z"
        transform="translate(-74.71 -12)"
      ></path>
      <g
        fill="#fc583d"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M818.37 255.58c-10.25 14.87-20.88 30.42-31.08 47.12l-96.88-44.46c6-18.61 10.95-36.81 15.56-54.27zM747.29 447.88L605.99 383c16.7-14 31.76-27.47 43.72-41.34l100.58 46.18c-2.77 18.09-3.17 38.26-3 60.04z"></path>
      </g>
      <path
        fill="#db4531"
        fillRule="evenodd"
        d="M725.1 355.72l34.9 15.5c-8.79 14.37-18.82 29.12-29.72 44.75l-47.78-20.68a364.64 364.64 0 0042.6-39.57zM767 269.35c6-17.35 10-34.59 14.58-50.88l38 17.35c-6.5 15.46-11.75 31.95-19.15 48.69z"
        transform="translate(-74.71 -12)"
      ></path>
      <path
        fill="#f2f2f2"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M78.79 76.59C65.2 76 52.51 79 43.58 83.13c-23.84 11.1-58.2 48-34.73 98.27s73.44 101.84 106.77 173.22-14.55 196.7 47.22 328.89c38 81.41 101.3 150.11 144.41 191.09h133.04l42.78-19.92s6.43-162.3-55.33-294.49-188.77-176.14-222.12-247.52S164.73 170 141.29 119.74c-14.7-31.4-39.86-42.19-62.5-43.15z"
      ></path>
      <path
        fill="#e6e6e6"
        fillRule="evenodd"
        d="M121.75 96.86c-23.84 11.1-58.2 48-34.73 98.27S160.44 297 193.79 368.35 179.25 565 241 697.19c38 81.4 101.3 150.16 144.41 191.14h76.91C436.77 849.2 378.11 756 331.06 655.28c-61.76-132.17-73.3-229.8-106.66-301.19s-68.85-129.69-92.32-179.91-17.96-73.77-10.33-77.32z"
        transform="translate(-74.71 -12)"
      ></path>
      <g
        fill="#fc583d"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M168.98 205.41c4.73 17.43 9.77 35.61 15.88 54.17l-96.63 45c-10.32-16.61-21-32.14-31.39-46.94zM269.93 383.9l-141 65.64c.08-21.79-.44-42-3.3-60l100.36-46.74c12.04 13.81 27.18 27.2 43.94 41.1z"></path>
      </g>
      <path
        fill="#db4531"
        fillRule="evenodd"
        d="M202.33 402.45l34.59-16c6 14.95 11.21 32.69 16.88 49.89l-48.25 22.22c.08-19.56-.35-39.85-3.22-56.11zM163.43 314.31c-10.52-15.78-19.79-30.81-30.35-44.87L172 252.57c8.6 14.92 15.92 30.13 25 46.54z"
        transform="translate(-74.71 -12)"
      ></path>
      <path
        fill="#f2f2f2"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M440.73 1c-26.3-.08-73.1 18.71-73.28 74.14s23 123.34 22.71 202.11S293.17 449 292.7 594.9c-.42 131.2 56.92 255.66 69 280.51h152.75c12.69-25.83 70.06-149.09 70.48-279.53.47-145.88-95.64-239.54-95.41-318.31s23.89-146.52 24.07-201.95S467.03 1.08 440.73 1z"
      ></path>
      <path
        fill="#e6e6e6"
        fillRule="evenodd"
        d="M517.17 14.73c-26.3-.09-73.1 18.69-73.28 74.13s23 123.35 22.71 202.11-97 171.81-97.46 317.66c-.42 130 55.8 253.19 68.58 279.7h52.09c-4.08-26.82-21.68-149.6-21.26-279.37.47-145.85 31.6-239.11 31.86-317.88s-7-146.63-6.82-202 15.17-74.38 23.58-74.35z"
        transform="translate(-74.71 -12)"
      ></path>
      <g
        fill="#fc583d"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M502.1 164.92c-3.14 17.78-6.34 36.37-8.72 55.77l-106.63-.35c-2.26-19.41-5.33-38-8.39-55.82zM517.35 369.33l-155.53-.5c9.35-19.68 17.48-38.15 22.6-55.72l110.7.36c5 17.6 13.01 36.12 22.23 55.86z"></path>
      </g>
      <path
        fill="#db4531"
        fillRule="evenodd"
        d="M460.16 326.9h38.54c-1.63 16.57-3.73 33.75-6.76 52.33l-53.32.17a330.5 330.5 0 0021.54-52.5zM463.26 230.7c-2.35-18.21-5.28-35.84-8.46-52.53l43.65.13c1 16.68 1.95 34.13 2.66 52.33z"
        transform="translate(-74.71 -12)"
      ></path>
      <path
        fill="#4d4d4d"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M441.38 396.47a288.12 288.12 0 00-111.71 21.11c-144.74 58.58-214.74 221-158.47 364.69l22.53-151.08h98l-28.44 190.39h149.3l-8.35 56.48h46.81l-16.16-28.89 12.71-84.56h98l-9.11 60.48h74.29l21.06-141h-71.38l8-52.92h151.62a281.5 281.5 0 00-17-58.48C658.22 464 552.97 397.8 441.38 396.47z"
      ></path>
      <path
        fill="#357180"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M193.13 631.19l-22.34 151.08c.64 1.65 1.06 3.33 1.73 5a282.68 282.68 0 0059.87 90.82h169.45l8.28-56.48h-148l28.17-190.42zM567.1 629.46l-7.94 52.92h71.18l-21 141h-74.05l9.08-60.47h-97.73l-12.67 84.56 16.1 28.88h191.22c63.27-64.35 93-156.35 77-246.89z"
      ></path>
      <path
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M552.15 520.41a27 27 0 1116.14-30.77 27.28 27.28 0 01-16.14 30.77zM484.09 581.85a27 27 0 1116.2-30.78 27.34 27.34 0 01-16.2 30.78zM567.11 590.59a27 27 0 1116.18-30.78 27.32 27.32 0 01-16.18 30.78z"
      ></path>
      <path
        fill="#357180"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M124.1 896.86l-14.77 97.3h108.18l5.78-38.16-4.65-10.31 7.74-10.42 5.89-38.45zm36 20.85h29.69l-2.65 17.49h-29.67zM154.33 956h29.75l-2.7 17.28h-29.7zM247.79 896.86L233 994.16h108.2l14.8-97.3zm36 20.85h29.74l-8.43 55.61h-29.72zM458.16 977.67H447.9l12.31-80.82H421l-12.32 80.82h-10.27l12.32-80.82H371.5l-14.75 97.33H495l14.75-97.33h-39.27zM510.49 994.18h99l3.34-22.26h-59.77l11.4-75.07h-39.22zM625 994.18h39.23L679 896.85h-39.25zM748.71 994.18h39.23l14.75-97.33h-39.23l-8.59 56.65-21.13-56.65h-39.23l-14.74 97.33H719l8.59-56.76zM862.39 956.05h15.81l-2.66 17.26h-29.72l8.44-55.59H884l-1.44 9.37h39.22l4.64-30.24h-108.2l-14.74 97.33h108.17l8.89-59h-55zM105.85 1000.27l-1.35 11.23h807.35l1.35-11.23H105.85z"
        transform="translate(-74.71 -12)"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M437.17 396.47c-158.56 0-287.09 125.93-287.09 281.27 0 74.18 29.55 141.43 77.45 191.68h419.33c47.89-50.25 77.4-117.5 77.4-191.68.03-155.34-128.54-281.27-287.09-281.27z"
      ></path>
      <path
        fill="#357180"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M121.43 879.7L120 890.93h807.42l1.41-11.23h-807.4z"
        transform="translate(-74.71 -12)"
      ></path>
    </svg>
  );
}

export default LjBowlingLogo;
