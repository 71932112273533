import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import AppStart from "./AppStart";
import Calendar from "./views/calendar/Calendar";

export default function App() {

  return (
    <BrowserRouter className="App">
      <Routes>
          <Route path="/" element={<AppStart />} />
          <Route path="/info" element={<AppStart />} />
          <Route path="/lag" element={<AppStart />} />
          <Route path="/scoring" element={<AppStart />} />
          <Route path="/kalender" element={<Calendar />} />
          <Route path="/kontakt" element={<AppStart />} />
        </Routes>
    </BrowserRouter>
  );
}
