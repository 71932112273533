import React, { useState, useRef } from "react";
import { useSpring, useTrail, animated as a } from "react-spring";
import "./start.css";

const trailsArr = [
  {
    d: "M44.64,457.41a13.42,13.42,0,0,0-9.28-3.33,4.45,4.45,0,0,0-2.68.77,2.17,2.17,0,0,0-1.09,1.92c0,1,.77,2.05,2.37,3.14.44.32,1.28.83,2.43,1.53l1.41.84,1.4,1a19.16,19.16,0,0,1,5.25,4.86,13.1,13.1,0,0,1,2.37,7.81,11.54,11.54,0,0,1-4.1,9.21c-2.75,2.37-6.59,3.59-11.39,3.59-5.57,0-9.53-1.16-11.9-3.4V473.92c3.52,3,6.85,4.48,10,4.48,2.31,0,3.46-.83,3.46-2.49,0-1.16-.77-2.31-2.37-3.46l-2.5-1.6L26.66,470l-1.41-.9c-5.12-3.33-7.68-7.42-7.68-12.35A11.49,11.49,0,0,1,22,447.3c2.88-2.37,6.85-3.52,11.77-3.52s8.64,1.09,10.88,3.2Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M59,466.24c0-7.1,2-12.73,6-16.83,3.65-3.77,8.45-5.63,14.34-5.63S90,445.64,93.6,449.41c4,4.1,6,9.73,6,16.83s-2,12.74-6,16.77c-3.65,3.78-8.45,5.7-14.33,5.7s-10.69-1.92-14.34-5.7C61,479,59,473.35,59,466.24Zm13.5-24.19a4.44,4.44,0,0,1-3.32-1.21,3.68,3.68,0,0,1-1.22-2.95A3.75,3.75,0,0,1,69.16,435a5.38,5.38,0,0,1,6.65,0A3.79,3.79,0,0,1,77,437.89a3.71,3.71,0,0,1-1.22,2.95A4.45,4.45,0,0,1,72.48,442.05Zm6.79,34.56q6.33,0,6.33-10.37t-6.33-10.36q-6.34,0-6.34,10.36T79.27,476.61Zm6.91-34.56a4.5,4.5,0,0,1-3.39-1.21,3.68,3.68,0,0,1-1.22-2.95A3.75,3.75,0,0,1,82.79,435a4.72,4.72,0,0,1,3.39-1.15A4.8,4.8,0,0,1,89.51,435a4.35,4.35,0,0,1,0,5.89A4.57,4.57,0,0,1,86.18,442.05Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M128.16,444.61a21.13,21.13,0,0,1,14.91,5.57c4.29,4,6.4,9.34,6.4,16.06s-2.11,12-6.4,16.07a21.12,21.12,0,0,1-14.91,5.56h-15V444.61Zm0,31.74c5.12,0,7.68-3.39,7.68-10.11s-2.56-10-7.68-10h-1.92v20.15Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M189.67,487.87H162V444.61h27.45V456.2H175.14V461h12.41v10.3H175.14v5.05h14.53Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M223.27,487.87l-5.76-15.1H216v15.1H202.85V444.61h15.74q7.59,0,12.29,3.84a12.66,12.66,0,0,1,4.67,10.24,13.73,13.73,0,0,1-1.92,7.1,12,12,0,0,1-5,4.93l1.15,1.47,8.06,15.68Zm-4.8-23.29a3.08,3.08,0,0,0,2.49-1.15,4.64,4.64,0,0,0,1-3.07,4.58,4.58,0,0,0-1-3,3.08,3.08,0,0,0-2.49-1.15h-2.82v8.38Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M273.18,457.41a13.42,13.42,0,0,0-9.28-3.33,4.45,4.45,0,0,0-2.68.77,2.17,2.17,0,0,0-1.09,1.92c0,1,.77,2.05,2.37,3.14.44.32,1.28.83,2.43,1.53l1.41.84,1.4,1A19.16,19.16,0,0,1,273,468.1a13.1,13.1,0,0,1,2.37,7.81,11.54,11.54,0,0,1-4.1,9.21c-2.75,2.37-6.59,3.59-11.39,3.59-5.56,0-9.53-1.16-11.9-3.4V473.92c3.52,3,6.85,4.48,10,4.48,2.31,0,3.46-.83,3.46-2.49,0-1.16-.77-2.31-2.37-3.46l-2.5-1.6L255.2,470l-1.41-.9c-5.12-3.33-7.68-7.42-7.68-12.35a11.52,11.52,0,0,1,4.42-9.47c2.88-2.37,6.85-3.52,11.77-3.52s8.64,1.09,10.88,3.2Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M314.85,476.35v11.52H289.57V444.61h13.18v31.74Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M348.26,482.75H337.89l-1.15,5.12H323.42L336,444.61h14.21l12.54,43.26H349.41Zm-16.64-44.92a3.75,3.75,0,0,1,1.21-2.94,5.36,5.36,0,0,1,6.66,0,3.75,3.75,0,0,1,1.21,2.94,3.68,3.68,0,0,1-1.21,2.94,5.15,5.15,0,0,1-6.66,0A3.68,3.68,0,0,1,331.62,437.83Zm11.2,22.27L340,473h6.14l-2.81-12.86-.26-3.9Zm2.43-22.27a3.75,3.75,0,0,1,1.21-2.94,5.45,5.45,0,0,1,6.72,0,4.33,4.33,0,0,1,0,5.88,5.24,5.24,0,0,1-6.72,0A3.68,3.68,0,0,1,345.25,437.83Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M402.27,456.2h-8.64v31.67H380.51V456.2h-8.7V444.61h30.46Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M442.46,456.2h-8.64v31.67H420.7V456.2H412V444.61h30.46Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M480.16,457.41a13.44,13.44,0,0,0-9.28-3.33,4.46,4.46,0,0,0-2.69.77,2.19,2.19,0,0,0-1.09,1.92c0,1,.77,2.05,2.37,3.14.45.32,1.28.83,2.43,1.53l1.41.84,1.41,1A19.45,19.45,0,0,1,480,468.1a13.1,13.1,0,0,1,2.36,7.81,11.56,11.56,0,0,1-4.09,9.21c-2.75,2.37-6.59,3.59-11.39,3.59-5.57,0-9.54-1.16-11.91-3.4V473.92c3.52,3,6.85,4.48,10,4.48q3.45,0,3.45-2.49c0-1.16-.77-2.31-2.37-3.46-.57-.38-1.4-.9-2.49-1.6l-1.34-.83-1.41-.9c-5.12-3.33-7.68-7.42-7.68-12.35a11.51,11.51,0,0,1,4.41-9.47c2.88-2.37,6.85-3.52,11.78-3.52s8.64,1.09,10.88,3.2Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M532.89,444.61q6.83,0,10.75,3.27a11.46,11.46,0,0,1,3.84,9.15,8.62,8.62,0,0,1-1.92,5.37,9.54,9.54,0,0,1-5.24,3.46,10.08,10.08,0,0,1,6.4,3.45,11.11,11.11,0,0,1,2.3,7.17,10,10,0,0,1-4,8.39c-2.62,2-6.33,3-11.07,3H515.74V444.61ZM531.49,462c1.85,0,2.81-1,2.81-3.14s-.83-3.07-2.43-3.07H528.8V462Zm1.27,15a2.67,2.67,0,0,0,2-.83,3.51,3.51,0,0,0-.25-4.8,3.69,3.69,0,0,0-2.37-.89H528.8V477Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M558.88,466.24c0-7.1,2-12.73,6-16.83,3.65-3.77,8.45-5.63,14.34-5.63s10.68,1.86,14.33,5.63c4,4.1,6,9.73,6,16.83s-2,12.74-6,16.77c-3.65,3.78-8.45,5.7-14.33,5.7s-10.69-1.92-14.34-5.7C560.86,479,558.88,473.35,558.88,466.24Zm20.29,10.37q6.33,0,6.33-10.37t-6.33-10.36q-6.34,0-6.34,10.36T579.17,476.61Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M665.5,444.61l-9.09,43.26H643.87l-6.59-21.56-.32-1.54-.32,1.54L630,487.87H617.5l-9.09-43.26h14.15l3.32,18.63.32,1.53.32-1.53,5.64-18.63h9.59l5.64,18.63.32,1.53.32-1.53,3.32-18.63Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M704.35,476.35v11.52H679.07V444.61h13.18v31.74Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M729.37,487.87H716.25V444.61h13.12Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M780.06,444.61v43.26H767.64l-12-21.95.39,3.33v18.62H742.94V444.61h12.54l11.84,21.7-.38-3.07V444.61Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M815.71,471.49h-4.48v-9h15.61v23.61c-3.58,1.73-8.32,2.63-14.14,2.63-6.34,0-11.46-2-15.3-6.15s-5.82-9.6-5.82-16.32,1.92-12.16,5.82-16.32,9-6.14,15.3-6.14c5.57,0,9.66.7,12.29,2.18v11.58a19.9,19.9,0,0,0-10-2.37,8.93,8.93,0,0,0-6.72,2.63c-1.85,1.92-2.75,4.73-2.75,8.44,0,7.36,2.62,11,7.94,11a12.47,12.47,0,0,0,2.24-.26Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M877.79,487.87h-14V472.51h-8.38v15.36h-14V444.61h14V461.7h8.38V444.61h14Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M912.86,482.75H902.49l-1.15,5.12H888l12.54-43.26h14.21l12.54,43.26H914Zm-5.44-22.65L904.6,473h6.15l-2.82-12.86-.26-3.9Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M966.17,476.35v11.52H940.89V444.61h13.18v31.74Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
  {
    d: "M1003.35,476.35v11.52H978.08V444.61h13.18v31.74Z",
    transform: "translate(-17.57 -433.73)",
    fill: "#357180",
  },
];
export default function LjBowlingText(props) {
  const transition = useSpring({
    delay: 1600,
    to: {
      opacity: !props.isInView ? 0 : 1,
    },
  });
  const trail = useTrail(trailsArr.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: props.isInView ? 1 : 0,
    height: props.isInView ? 110 : 0,
    from: { opacity: 0, height: 0 },
  });
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="985.78"
      height="156.54"
      data-name="Layer 1"
      viewBox="0 0 985.78 156.54"
      className="StartInnerText"
    >
      <g>
        {trail.map((style, index) => (
          <a.path
            d={trailsArr[index].d}
            transform={trailsArr[index].transform}
            fill="#357180"
            key={index}
            style={style}
          />
        ))}
      </g>
      <a.text
        fill="#333f38"
        fontFamily="Poppins-Medium, Poppins"
        fontSize="41"
        fontWeight="500"
        transform="translate(193.74 132.67)"
        style={transition}
      >
        Söderslättshallen i Trelleborg
      </a.text>
    </svg>
  );
}
