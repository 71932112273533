import React, { useState, useRef } from "react";
import CalendarModule from 'react-awesome-calendar';
import ICalParser from 'ical-js-parser';
import moment from 'moment';
import "./calendar.css";

const axios = require('axios');

export default function Calendar() {
  const ref = useRef();
const [events, setEvents] = useState(false);

    if (events === false) {
        let eventsArray = [];
        axios.get('https://api.swebowl.se/v1/Calendar/Hall/809?seasonId=2021&nocache')
        .then(function (response) {
            // handle success
            let directEvents = ICalParser.toJSON(response.data)
            for (let i = 0; i < directEvents.events.length; i++) {
                let tempFrom = moment.utc(directEvents.events[i].dtstart.value)
                let tempTo = moment.utc(directEvents.events[i].dtend.value)
                let tempObj = {
                    id: directEvents.events[i].uid,
                    from: tempFrom,
                    to: tempTo,
                    title: directEvents.events[i].summary,
                    location: directEvents.events[i].location,
                    color: '#4D4D4D'
                }
                eventsArray.push(tempObj)
              }
              console.log(eventsArray)
            setEvents(eventsArray)
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        });
    }
 

  return (
    <div ref={ref} className="Calendar">
          {events ? (
                <div 
                    className='CalendarDiv'
                    >
                    <CalendarModule
                        events={events}
                    />
                </div>
            ):(
                null
            )}
            </div>
  );
}
