import React, { useState, useRef } from "react";
import { Waypoint } from "react-waypoint";
import { useSpring, animated as a } from "react-spring";
import { useSelector, useDispatch } from "react-redux";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import { useOnScreen } from "../../components/utils/utils";
import startImage from "../../static/start.jpg"; 
import infoIcon from "../../static/infoIcon.svg"; 
import bowlingStart from "../../static/bowlingStart.jpg"; 

import "./info.css";

export default function Info() {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const pathListener = useSelector((state) => state.app.pathListener);
  const [isInView, setInView] = useState(false);

  if (isVisible === true && window.location.pathname !== "/info") {
    window.history.replaceState(null, "Info", "/info");
  }

  const transition = useSpring({
    delay: 100,
    to: {
      opacity: !isInView ? 0 : 1,
    },
  });

  return (
    <Element name="info" className="Info">
      <Waypoint
        bottomOffset={window.innerHeight * 0.3}
        scrollableAncestor={window}
        onEnter={() => setInView(true)}
      >
        <a.div style={transition} ref={ref} className="Info">
          <img src={startImage} alt="Logo" className="InfoImage" />
              <div className="InfoInnerText">
                  <p className="InfoTitle">BOWLING</p>
                  <p className="InfoText">
                    Med våra åtta bowling-banor har vi öppet för både föreningar och privatpersoner alla dagar i veckan och tar emot bokningar på telefon och epost.
                  </p>
                  <p className="InfoText">
                   Skor och klot finns att låna i hallen vid bokning av bana.
                  </p><p className="InfoText">
                   Pris: 150kr/h per bana
                  </p>
                </div>
                <div className="InfoInnerIconFirst">
                  <img src={infoIcon} alt="Logo" className="InfoIcon" />
                </div>
                <div className="InfoInnerTextBottom">
                  <p className="InfoTitle">SERVERING</p>
                  <p className="InfoText">
                    I bowlinghallen finns även ett cafe med utbud av olika drycker och fika.
                  </p>
                </div>
                <img src={bowlingStart} alt="Logo" className="InfoImage" />
            </a.div>
      </Waypoint>
    </Element>
  );
}
